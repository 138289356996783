<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Academic Integrity Agreement</h2>

      <p class="mb-3">
        <b>Academic Integrity for Lab Courses</b>:
        <i
          >all students are required to complete the academic integrity & communication agreement
          before entering the lab for the first time</i
        >. Plagiarism (copying) is not permitted on any assignment. Furthermore, lab reports must be
        an individual effort - the work turned in must be your own unique output. Providing your
        work to another student is considered collusion, as they may plagiarize from your work.
      </p>

      <p class="mb-3">
        To read the full UCI Statement on Academic Integrity, please go to
        <a
          href="https://senate.uci.edu/committees/boards/academic-integrity-review-board-airb/"
          rel="noopener noreferrer"
          target="_blank"
          >https://senate.uci.edu/academic-integrity/
        </a>
        (Read the whole policy if you have never done so before.) The most important part is this:
        <i
          >It is essential that all members of the University practice academic integrity and accept
          individual responsibility for their work and actions. Violating the Academic Integrity
          Policy is unacceptable, devaluing the teaching and learning experience for the entire
          community. Furthermore, Failure to comply with the Academic integrity policies will result
          in a referral to OAISC</i
        >. In the classroom and lab it is important to acknowledge the honesty and professional
        conduct is required. Check off all items indicating that you will uphold them.
      </p>

      <p class="mb-3">
        In the classroom and lab it is important to acknowledge the honesty and professional conduct
        is required. <i>Check off all items indicating that you will uphold them</i>.
      </p>

      <p v-for="option in optionsQ4" :key="'pt-1-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.question4Values" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';

export default {
  name: 'UCIrvineIntegrityAgreementQ4',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        question4Values: [],
      },
      optionsQ4: [
        {
          text: 'Conduct myself at all times with integrity.',
          value: 'integrityAnswer',
        },
        {
          text: 'Be properly prepared for every laboratory session by completing all pre‑lab work and reading.',
          value: 'preparedAnswer',
        },
        {
          text: 'Perform all experiments thoroughly and to the best of my ability.',
          value: 'experimentsAnswer',
        },
        {
          text: 'Work equally and professionally with my labmates to perform lab work.',
          value: 'labmatesAnswer',
        },
        {
          text: 'Collect all data during lab in my electronic laboratory notebook (ELN).',
          value: 'notebookAnswer',
        },
        {
          text: 'Never fabricate data or results.',
          value: 'fabricateAnswer',
        },
        {
          text: 'Complete lab reports independently (by myself).',
          value: 'independentAnswer',
        },
        {
          text: "Never copy another student's work or allow another student to copy from me. If another student's experimental write‑up lab manual is found in my possession it will be assumed I am copying. I will be accused of cheating and a letter will be placed in my academic file.",
          value: 'cheatingAnswer',
        },
        {
          text: 'Never post my work to any internet site. If another student reports the presence of my work or is found in possession of my work from such a site, I will be accused of collusion and a letter will be placed in my academic file.',
          value: 'collusionAnswer',
        },
        {
          text: 'Use & report legitimate information sources that help with the solution process and do not provide answers.',
          value: 'sourcesAnswer',
        },
        {
          text: 'Report any incidents of cheating to the course instructor.',
          value: 'reportAnswer',
        },
        {
          text: 'Complete all course work in accordance with the listed statements. Failure to adhere to any of the statements in this agreement will result in a failing grade in the course and a letter in my academic file.',
          value: 'completeAnswer',
        },
      ],
    };
  },
};
</script>
